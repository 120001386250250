<template>
  <div class="container mx-auto flex flex-wrap px-6 justify-center" id="Hochzeitsportal24">
    <a class="m-4" href="https://www.hochzeitsportal24.de/blog/fotoaufgaben-hochzeit/#attachment_92078" target="_blank" rel="nofollow noopener noreferrer">
      <lazy-component>
        <picture>
          <source srcset="/images/Featured-on-Hochzeitsportal24-200x200px.webp" type="image/webp">
          <source srcset="/images/Featured-on-Hochzeitsportal24-200x200px.png" type="image/png">
          <img src="/images/Featured-on-Hochzeitsportal24-200x200px.png" alt="Featured on Hochzeitsportal24">
        </picture>
      </lazy-component>
    </a>
    <!-- ProvenExpert Bewertungssiegel -->
    <!--
    <a class="m-4" href="https://www.provenexpert.com/photasky/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget" title="Kundenbewertungen &amp; Erfahrungen zu photasky. Mehr Infos anzeigen." target="_blank" style="text-decoration:none;" rel="noopener noreferrer">
      <lazy-component>
        <img src="https://images.provenexpert.com/cd/17/74154ac4992ef643a2f2079528a4/widget_portrait_180_de_0.png" alt="Erfahrungen &amp; Bewertungen zu photasky" width="180" height="216" style="border:0" />
      </lazy-component>
    </a>
    -->
    <!-- ProvenExpert Bewertungssiegel -->
  </div>
</template>
